import React from "react"
import styles from "./ContactBox.module.scss"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const ContactBox = ({ heading, info, link, linkType, icon, linkName }) => {
  const Icon = icon

  return (
    <div className={styles.contactBox}>
      <div className={styles.icon}>
        <Icon />
      </div>
      <div className={styles.contact}>
        <div className={styles.secondary}>{heading}</div>
        {link && linkType && (
          <a
            href={`${linkType ? linkType : ""}${link}`}
            className={`${styles.primary} ${styles.link}`}
          >
            {linkName ? linkName : link}
          </a>
        )}
        {link && !linkType && (
          <Link to={link} className={`${styles.primary} ${styles.link}`}>
            {linkName ? linkName : link}
          </Link>
        )}
        {info && <div className={styles.primary}>{info}</div>}
      </div>
    </div>
  )
}

export default ContactBox

ContactBox.propTypes = {
  heading: PropTypes.string.isRequired,
  info: PropTypes.string,
  link: PropTypes.string,
  linkType: PropTypes.string,
  icon: PropTypes.func,
  linkName: PropTypes.string,
}
