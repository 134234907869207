import React from "react"
import NavLinks from "./NavLinks"
import PropTypes from "prop-types"
import styles from "./Mobnav.module.scss"

const Mobnav = ({ open, setOpenHandler }) => {
  return (
    <>
      <div
        onClick={setOpenHandler}
        onKeyDown={setOpenHandler}
        className={`${styles.overlay} ${open && styles.active}`}
        role="button"
        tabIndex={0}
        aria-label="Close menu"
      />
      <aside className={`${styles.sidebar} ${open && styles.open}`}>
        <NavLinks />
      </aside>
    </>
  )
}

export default Mobnav

Mobnav.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpenHandler: PropTypes.func.isRequired,
}
