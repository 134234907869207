import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const getLinks = graphql`
  query {
    allContentfulSluzby(sort: { fields: createdAt, order: ASC }) {
      services: edges {
        node {
          id
          nazevSluzby
          slug
        }
      }
    }
  }
`
const ServicesLinks = () => {
  const {
    allContentfulSluzby: { services },
  } = useStaticQuery(getLinks)

  return (
    <ul>
      {services.map((service, i) => {
        return (
          <Link
            key={service.node.id}
            to={`/${service.node.slug}`}
            activeClassName="active-link"
            partiallyActive={true}
          >
            <li>{service.node.nazevSluzby}</li>
          </Link>
        )
      })}
    </ul>
  )
}

export default ServicesLinks
