import { Link } from "gatsby"
import React from "react"
import styles from "./Button.module.scss"
import PropTypes from "prop-types"

const Button = ({ link, children, color, type, disabled }) => {
  const style = color ? styles[color] : ""

  if (link) {
    return (
      <Link to={link} className={`${styles.button} ${style}`}>
        {children}
      </Link>
    )
  } else {
    return (
      <button
        type={type ? type : "button"}
        className={`${styles.button} ${style}`}
        disabled={!!disabled}
      >
        {children}
      </button>
    )
  }
}

export default Button

Button.propTypes = {
  link: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  type: PropTypes.string,
}
