import React, { useState } from "react"
import links from "../../constants/links"
import styles from "./NavLinks.module.scss"
import { Link } from "gatsby"
import { MdArrowDropDown } from "react-icons/md"
import OutsideClickHandler from "react-outside-click-handler"
import AnimateHeight from "react-animate-height"
import ServicesLinks from "../services/ServicesLinks"

const NavLinks = () => {
  const [dropdown, setDropdown] = useState(false)
  const [height, setHeight] = useState(0)

  const setDropdownHandler = () => {
    setDropdown(dropdown => !dropdown)
  }

  const toggleHeight = () => {
    height === 0 ? setHeight("auto") : setHeight(0)
  }

  const handlers = () => {
    setDropdownHandler()
    toggleHeight()
  }

  return (
    <ul className={styles.nav}>
      {links.map((link, index) => {
        return (
          <li
            key={index}
            className={`${styles.item} ${
              !link.path && dropdown ? styles.activeItem : ""
            }`}
            onClick={link.dropdown ? handlers : undefined}
            onKeyDown={link.dropdown ? handlers : undefined}
            aria-expanded={link.dropdown && height !== 0}
            aria-controls={link.dropdown && "drop"}
          >
            {link.path ? (
              <Link className={styles.link} to={`${link.path}`}>
                {link.text}
              </Link>
            ) : (
              <button className={`no-highlihght ${styles.wrapper}`}>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    if (dropdown) handlers()
                  }}
                >
                  <div className={styles.texticon}>
                    <span>{link.text}</span>
                    {link.dropdown && (
                      <MdArrowDropDown
                        className={`${styles.arrow} ${dropdown ? styles.rotate : ""}`}
                      />
                    )}
                  </div>
                  {link.dropdown && (
                    <AnimateHeight
                      id="drop"
                      duration={400}
                      height={height}
                      className={`${styles.dropdown} no-highlihght`}
                    >
                      <ServicesLinks />
                    </AnimateHeight>
                  )}
                </OutsideClickHandler>
              </button>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default NavLinks
