export const debounce = (func, delay) => {
  let inDebounce
  return (...args) => {
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => { 
      func(...args)
    }, delay)
  }
};

export const throttle = (func, limit) => {
  let lastFunc
  let lastRan
  return function() {
    const context = this
    const args = arguments
    if (!lastRan) {
      func.apply(context, args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(function() {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args)
          lastRan = Date.now()
        }
      }, limit - (Date.now() - lastRan))
    }
  }
}

export const calcViewPortHeight = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--height', `${vh}px`);    
  }

export function shorten(str, maxLen, separator = ' ') {
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen));
}

export const insertMap = () => {
  return {__html: `
  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10434.982978749244!2d16.7297757!3d49.1674341!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x265ca3f316f47307!2sAgo-top%2C%20S.r.o.!5e0!3m2!1scs!2scz!4v1605554060531!5m2!1scs!2scz" width="800" height="600" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`
    }
} 
