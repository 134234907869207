import React from "react"
import styles from "./Map.module.scss"

const insertMap = () => {
  return {
    __html: `
  <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10434.982978749244!2d16.7297757!3d49.1674341!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x265ca3f316f47307!2sAgo-top%2C%20S.r.o.!5e0!3m2!1scs!2scz!4v1605554392483!5m2!1scs!2scz" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`,
  }
}

const Map = ({ place }) => {
  return (
      <div
        className={place === "footer" ? styles.mapFooter : styles.map}
        dangerouslySetInnerHTML={insertMap()}
      />
  )
}

export default Map
