import { useState, useEffect } from 'react';
import {throttle} from '../functions/functions'

const getWidth = () => window.innerWidth;

function useCurrentWidth() {
  
  let [width, setWidth] = useState(900);

  useEffect(() => {
    setWidth(typeof window !== 'undefined' && window.innerWidth);
    const resizeListener = () => {
      setWidth(getWidth())
    };
    window.addEventListener('resize', throttle(resizeListener,150));
    return () => {
      window.removeEventListener('resize', throttle(resizeListener,150));
    }
  }, [])

  return width;
}

export default useCurrentWidth
