import React, {useState, createContext, useEffect} from 'react'
import '../../styles/fonts.scss'
import '../../styles/reset.scss'
import '../../styles/resources.scss'
import '../../styles/global.scss'
import Header from '../header/Header'
import Footer from './footer/Footer'
import 'focus-visible';
import PropTypes from 'prop-types';

export const StatStateContext = createContext(null)

const Layout = ({children}) => {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        let resizeTimer;
        const preventAnimations = () => {
            document.body.classList.add("resize-animation-stopper");
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
              document.body.classList.remove("resize-animation-stopper");
            }, 300);
        }
        window.addEventListener("resize", () => {
            preventAnimations();
        });
        return window.removeEventListener("resize", () => {
            preventAnimations();
        })
    }, [])

    const setOpenHandler = () => {
        setOpen(open => !open);
    }
    
    return (
        <>
            <Header open={open} setOpenHandler={setOpenHandler}/>
                {children}
            <Footer/>
        </>
    )
}

export default Layout

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}
