import React from "react"
import styles from "./Header.module.scss"
import NavLinks from "./NavLinks"
import { Logo } from "../icons/Icons"
import Button from "../Button"
import Mobnav from "./Mobnav"
import { useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { debounce } from "../../functions/functions"
import { graphql, useStaticQuery } from "gatsby"
import { MapIcon } from "../icons/Icons"
import { FiClock } from "react-icons/fi"
import { MdPhone } from "react-icons/md"
import useCurrentWidth from "../../hooks/useCurrentWidth"
import ContactBox from "./ContactBox"

const getContact = graphql`
  query {
    allContentfulProdejna {
      edges {
        node {
          pondeli
          utery
          streda
          ctvrtek
          patek
          sobot
          nedeleasvatky
        }
      }
    }
    allContentfulKontakt {
      edges {
        node {
          telefon
        }
      }
    }
  }
`

const Header = ({ open, setOpenHandler }) => {
  const { allContentfulProdejna, allContentfulKontakt } = useStaticQuery(
    getContact
  )
  const {
    pondeli,
    utery,
    streda,
    ctvrtek,
    patek,
    sobot,
    nedeleasvatky,
  } = allContentfulProdejna.edges[0].node

  const { telefon } = allContentfulKontakt.edges[0].node
  const days = [nedeleasvatky, pondeli, utery, streda, ctvrtek, patek, sobot]
  const dayIndex = new Date().getDay()
  const width = useCurrentWidth()

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"
      document.body.style.height = "100%"
    } else {
      document.body.style.overflow = "auto"
      document.body.style.height = "auto"
    }

    const widthOverflow = () => {
      if (window.innerWidth < 850 && open) {
        document.body.style.overflow = "hidden"
        document.body.style.height = "100%"
      } else {
        document.body.style.overflow = "auto"
        document.body.style.height = "auto"
      }
    }
    window.addEventListener("resize", debounce(widthOverflow, 200))
    return window.removeEventListener("resize", debounce(widthOverflow, 200))
  }, [open])

  return (
    <>
      <header className={styles.header}>
        <div className={styles.logo}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className={styles.info}>
          <ContactBox
            heading="Telefon"
            icon={MdPhone}
            link={telefon}
            linkType="tel:"
          />
          <ContactBox
            heading="Šlapanice"
            icon={MapIcon}
            link="/kontakt"
            linkName="Palackého 32"
          />
          <ContactBox heading="Dnes" icon={FiClock} info={days[dayIndex]} />
        </div>
      </header>
      <nav className={styles.navbar}>
        <div className={styles.nav}>
          {width > 850 && <NavLinks />}
          <Button color="white" link="/kontakt">
            Poptávka
          </Button>
        </div>
        <div className={styles.mobile}>
          <button onClick={setOpenHandler} className={`${styles.hamburger}`}>
            <div className={`${styles.lines} ${open && styles.active}`} />
            <div className={styles.menu}>
              <span className={open ? styles.open : styles.close}>Menu</span>
              <span className={open ? styles.close : styles.open}>Zavřít</span>
            </div>
          </button>
        </div>
        {width <= 850 && <Mobnav open={open} setOpenHandler={setOpenHandler} />}
      </nav>
    </>
  )
}

export default Header

Header.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpenHandler: PropTypes.func.isRequired,
}
