import React from "react"
import styles from "./Footer.module.scss"
import { GoMailRead } from "react-icons/go"
import { MdPhone, MdKeyboardArrowUp } from "react-icons/md"
import { FiClock } from "react-icons/fi"
import { IoIosPin } from "react-icons/io"
import { graphql, useStaticQuery } from "gatsby"
import { useInView } from "react-intersection-observer"
import Map from "../../map/Map"
import FooterInfo from "./FooterInfo"

const getContact = graphql`
  query {
    allContentfulKontakt {
      edges {
        node {
          telefon
          mail
          adresa
        }
      }
    }
    allContentfulProdejna {
      edges {
        node {
          pondeliPatek
          sobota
          nedele
        }
      }
    }
  }
`

const Footer = () => {
  const { allContentfulKontakt, allContentfulProdejna } = useStaticQuery(
    getContact
  )
  const { telefon, mail } = allContentfulKontakt.edges[0].node
  const { pondeliPatek, sobota, nedele } = allContentfulProdejna.edges[0].node

  const [footerRef, footerView] = useInView({
    threshold: 0,
    rootMargin: "300px 300px 300px 300px",
  })

  const [mapRef, mapView] = useInView({
    threshold: 0,
    rootMargin: "300px 300px 300px 300px",
    triggerOnce: true,
  })

  const scrollToTop = () => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <footer ref={footerRef} className={styles.footer}>
      <div ref={mapRef} className={styles.footerWrapper}>
        <div className={styles.arrowCont}>
          <button
            onClick={scrollToTop}
            className={`${styles.arrow} ${footerView ? styles.arrowShow : ""}`}
          >
            <MdKeyboardArrowUp />
          </button>
        </div>
        <div className={styles.contacts}>
          <h3 className={styles.head}>Kontakt</h3>
          <FooterInfo
            title="Telefon:"
            link={telefon}
            linkType="tel:"
            icon={MdPhone}
          />
          <FooterInfo
            title="Mail:"
            link={mail}
            linkType="mailto:"
            icon={GoMailRead}
          />
          <FooterInfo
            title="Adresa:"
            info="Palackého 212/32, Šlapanice"
            icon={IoIosPin}
          />
        </div>
        <div className={styles.contacts}>
          <h3 className={styles.head}>Otevírací doba</h3>
          <FooterInfo
            title="Pondělí - Pátek"
            info={pondeliPatek}
            icon={FiClock}
          />
          <FooterInfo title="Sobota" info={sobota} icon={FiClock} />
          <FooterInfo title="Neděle a svátky" info={nedele} icon={FiClock} />
        </div>
        <div className={styles.mapWrapper}>
          <h3 className={styles.head}>Kde nás najdete</h3>
          {mapView && <Map place="footer" />}
        </div>
      </div>
      <div className={styles.copyright}>
        <div className={styles.copyContainer}>
          <div className={styles.web}>
            Web vytvořil:{" "}
            <a
              href="https://www.linkedin.com/in/martin-hyttych/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Martin Hyttych
            </a>
          </div>
          <div>© 2020 AGO-TOP s.r.o.</div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
