export default [
    {
      text: "Služby",
      dropdown: true
    },
    {
      path: "/projekty",
      text: "Realizace",
    },
    {
      path: "/prodejna",
      text: "Prodejna",
    },
    {
      path: "/velkoobchod",
      text: "Velkoobchod",
    },
    {
      path: "/onas",
      text: "O nás",
    },
    {
      path: "/kontakt",
      text: "Kontakt",
    }
  ]