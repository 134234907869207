import styles from "./FooterInfo.module.scss"
import React from "react"
import PropTypes from 'prop-types';

const FooterInfo = ({title, link, linkType, info, icon}) => {

  let Icon = icon;

  return (
    <div className={styles.box}>
      <Icon className={styles.icon} />
      <div className={styles.description}>{title}</div>
      {link && 
        <a 
          className={`${styles.contact} ${styles.link}`} 
          href={`${linkType}${link}`}
        >
          {link}
        </a>
      }
      {info && <div className={styles.contact}>{info}</div>}
    </div>
  )
}

export default FooterInfo

FooterInfo.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  linkType: PropTypes.string,
  info: PropTypes.string,
  icon: PropTypes.func,
}